import _ from 'lodash'
import React from 'react'
import { Spinner, ErrorView } from '../../components'
import GradientBanner from '../../components/gradient-banner'
import './page-views-styles.scss'

const Text = ({ className, text }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: text }}></div>
)

const PageView = ({
  data,
  loading,
  error,
  errorMsg,
}) => {
  if (loading) {
    return <Spinner loading={loading} />
  }

  if (error) {
    return <ErrorView errorMsg={errorMsg} />
  }

  return (
    <>
      <div className="pages__container">
        <div className="pages__box">
          <h1 className='page-title'>{_.get(data, 'acf.rows[0].title')}</h1>
          <Text text={_.get(data, 'acf.rows[0].copy')} className="rich-text" />
        </div>
      </div>
      <GradientBanner />
    </>
  )
}

export default PageView
