import { ACTION_TYPES } from './dataActionsType'

export const INITAL_STATE = {
  loding: true,
  data: [],
  total_items: 0,
  total_pages: 0,
  error: false,
  errorMsg: '',
}

export const dataReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_START:
      return {
        ...state,
        loding: true,
        data: [],
        total_items: 0,
        total_pages: 0,
      }
    case ACTION_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        loding: false,
        error: false,
        data: action.payload.data,
        total_items: action.payload.total_items || 0,
        total_pages: action.payload.total_items || 0,
      }
    case ACTION_TYPES.FETCH_ERROR:
      return {
        ...state,
        loding: false,
        error: true,
        errorMsg: action.payload,
      }
    default:
      return state
  }
}
